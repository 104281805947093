export const Quiz = ({ className }) => (
  <svg
    className={className}
    fill="#5246F5"
    height="16"
    preserveAspectRatio="xMidYMid meet"
    width="16"
    viewBox="-1 -1 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="nonzero" d="M12.148496 0c1.1138 0 2.0165.902988 2.0165 2.016668v10.13334l-.0055303.1504972c-.0769695 1.043428-.947797 1.8662028-2.0109697 1.8662028H2.016506c-1.11378 0-2.016506-.903-2.016506-2.0167V2.016668C0 .902988.902726 0 2.016506 0h10.13199ZM12.664 7.833H7.832v4.833l4.316496.000708c.2852 0 .5165-.2313.5165-.5167L12.664 7.833Zm-6.332 0H1.5v4.317008l.00832451.0928866c.04374811.2410578.25469705.4238134.50818149.4238134L6.332 12.666V7.833ZM12.148496 1.5H7.832v4.833h4.832l.000996-4.316332c0-.28544-.2313-.516668-.5165-.516668ZM6.332 1.5H2.016506l-.09281933.00832118C1.682784 1.55205328 1.5 1.76294356 1.5 2.016668V6.333h4.832V1.5Zm3.9130949.83c.875334 0 1.5849051.70974202 1.5849051 1.58524684s-.7095711 1.58524684-1.5849051 1.58524684c-.87552377 0-1.5850949-.70974202-1.5850949-1.58524684S9.36957113 2.33 10.2450949 2.33Z"/>
  </svg>
);
