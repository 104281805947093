/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import ShoppingPost from './ShoppingPost/index'
import styles from './shoppingPosts.module.scss';

export const ShoppingPosts = ({ className = '', data = {} }) => {
  return (
    <section id="shopping-posts" className={`${className} ${styles['shoppingPosts']}`}>
      <h2>Shopping</h2>
      <>
        {data.items.map(({ content, id }, index) => (
          <ShoppingPost data={content} index={index} key={id} />
        ))}
      </>
    </section>
  );
};

ShoppingPosts.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default ShoppingPosts;
