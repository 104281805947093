import { useEffect, useState } from 'react';
import { useMediaQuery } from '../../../hooks';
import FeedTabs from '../../FeedTabs';

const HomeFeedTabs = ({ tabs }) => {
  const { breakpoint } = useMediaQuery();
  const [ adsIndex, setAdsIndex ] = useState(2);

  useEffect(() => {
    if (!breakpoint) return;

    if (breakpoint === 'xs' || breakpoint === 'sm') {
      setAdsIndex(2);
    } else {
      setAdsIndex(1);
    }

  }, [breakpoint]);

  return (
    <FeedTabs tabs={tabs} adsStartIndex={adsIndex} />
  );
};

export default HomeFeedTabs;
