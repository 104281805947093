export const UpwardTrend = ({ className = '' }) => (
  <svg
    className={className}
    fill="#5246F5"
    height="17"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 19 17"
    width="19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="evenodd" d="M18 8.2 17 .5 9.7 3.3l2.9 1.6-2.7 4.7L5.2 7 .6 15 3 16.4 6.3 11l4.6 2.7L15 6.4l3 1.8Z" clipRule="evenodd"/>
  </svg>
);
