import LinkWithTracking from '../../LinkWithTracking';
import { useViewImpression } from '../../../hooks';
import styles from '../promoBar.module.scss';

/**
 * @todo
 * Add functionality to handle the case when the user is not logged in.
 */

const ManualPromoBar = ({ className = '', title, description, cta, trackingData, isLoginned = true }) => {
  const trackingButtonData = {
    ...trackingData,
    item_type: 'button',
    item_name: isLoginned ? cta.text : 'log_in',
    target_content_type: isLoginned ? 'url' : 'auth',
    target_content_id: isLoginned ? cta.url : 'sign_up',
  };
  const { setObservable } = useViewImpression({
    trackingData: trackingButtonData
  });

  const attachImpressionObserver = (el) => {
    setObservable(el);
  }

  return (
    <aside className={`${className} ${styles.container}`} ref={obvEl => attachImpressionObserver(obvEl)}>
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.body}>{description}</p>
        <LinkWithTracking
          className={styles.button}
          href={cta.url}
          commonTrackingData={trackingButtonData}
        >{isLoginned ? cta.text : 'Log in'}</LinkWithTracking>
      </div>
    </aside>
  );
};

export default ManualPromoBar;
