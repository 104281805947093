export const FireSpeechBubble = ({ className = '' }) => (
  <svg
    className={className}
    fill="#5246F5"
    height="16"
    preserveAspectRatio="xMidYMid meet"
    width="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="evenodd" d="M11.4731.792949C10.3886.270365 9.20024-.00068393 7.99647.0000013 6.54963.00048209 5.12983.393432 3.88863 1.137c-1.24126.7436-2.25761 1.81-2.940799 3.08562C.264643 5.49823-.0597965 6.9353.00906607 8.38075.0731856 9.72663.444705 10.7299.947831 11.6241l-.816132 3.7418c-.0728326.1937-.0787286.2944 0 .3888.033382.0401.109298.0753.313006.0385l3.576745-1.0395c1.34107.8941 2.80008 1.2406 3.93997 1.2462 1.2038.006 2.39348-.2599 3.48018-.7777 1.0868-.518 2.0427-1.2744 2.7965-2.2132.7537-.9388 1.2858-2.0356 1.5567-3.20875.2709-1.17309.2736-2.39222.0079-3.5665-.2659-1.17427-.7932-2.2734-1.5429-3.21545-.7497-.94206-1.7023-1.70275-2.7867-2.225351ZM5.946 7.36257c.7377-.56879 1.30574-1.49499.63295-3.20312-.02938-.07458.02528-.15666.10538-.15402 1.82802.0604 2.43009 1.09792 2.55836 1.87954.0154.09385.13118.13859.19352.06678.18513-.21324.2759-.4203.31673-.7092.00969-.06862.07578-.11606.14102-.09274 1.70094.60786 3.24254 4.39953.51314 6.19799-.67927.4477-1.51952.7414-2.75148.7414-4.02876 0-4.18714-4.55934-2.28043-5.50883.06999-.03486.14824.01893.14974.09712.00738.38567.0546.59314.32112.70029.03346.01345.0714.00681.09995-.01521Zm1.13352 2.23897c.41319-.14889.53545-.40442.44522-1.15668-.00856-.07138.03905-.13948.11079-.14411.54078-.03487.90736.39661 1.00469.84493.02884.13283.24841.2445.3452.14906.09951-.09811.19368-.20217.25141-.29676.16012-.26234.24843-.31988.32434-.28465.78663.36504.95523 2.03717-.39481 2.92677-.34808.2293-.77862.3799-1.40987.3799-1.99323 0-2.13753-2.17808-1.26563-2.76607.07831-.05281.17304.01432.2129.09995.05886.12649.17512.20278.29296.2475.02663.0101.05601.00981.0828.00016Z" clipRule="evenodd"/>
  </svg>
);
