import React, { useCallback } from 'react';
import Sponsor from '../Sponsor';
import { Sparkles } from '../../icons/Sparkles';
import { ACTIVE_TAB_EVENT } from '../../constants/feeds';
import { useTrackingContext } from '../../hooks';
import styles from './sponsoredBar.module.scss';

export const SponsoredBar = ({ data = {}, sponsor = {}, tabs }) => {
  const { enabled, cta = {}, title, description } = data;
  const { trackContentAction } = useTrackingContext();

  const commonTrackingData = {
    unit_type: 'feed',
    unit_name: 'splash',
  };

  const onCtaClick = useCallback(() => {
    const setActiveTabEvent = new CustomEvent(ACTIVE_TAB_EVENT, { detail: 'tab_sponsored' });
    window.dispatchEvent(setActiveTabEvent);
    trackContentAction({
      ...commonTrackingData,
      item_name: 'go_to_feed',
      item_type: 'button',
      action_type: 'navigate',
      action_value: 'sponsored',
    });
  }, []);

  const hasTabContent = tabs && tabs.find(tab => tab.name === 'tab_sponsored' && !!tab?.items?.length);

  if (!enabled || !hasTabContent) {
    return null;
  }

  return (
    <aside className={styles.sponsoredBar}>
      <div className={styles.container}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.description}>{description}</p>
        <div className={styles.action} style={{'--sponsorTextColor': '--sponsorshipHighlightColor'}}>
          <button className={styles.cta} onClick={onCtaClick}>
            {!!cta?.icon?.length ? <img className={styles.icon} src={cta?.icon} alt="sponsor icon" aria-hidden="true"/>
              : <Sparkles className={styles.icon}/>}
            {cta.text}
          </button>
          <Sponsor
            data={sponsor}
            className={styles.sponsor}
            label="Promoted by"
            isTrackable={true}
            showLogo={false}
            trackingData={{
              ...commonTrackingData,
              tem_name: 'promoted_by',
              item_type: 'text',
              target_content_type: 'url',
            }}
          />
        </div>
      </div>
    </aside>
  );
};

export default SponsoredBar;
