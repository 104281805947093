/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import TrendingPosts from './TrendingPosts';
import MomentOfTheDay from './MomentOfTheDay';
import ShoppingPosts from './ShoppingPosts';
import FunModule from '../FunModule';
import styles from './splash.module.scss';

export const Splash = ({
  isTrackable = false,
  trackingData = {},
  zones = {},
}) => {
  const splashTrackingData = {
    ...trackingData,
    position_in_unit: 3,
    unit_name: 'splash',
    unit_type: 'feed',
  };

  return (
    <header className={`feed-content-area ${styles.splash}`}>
      <TrendingPosts data={zones.splash_trending} className={styles.trendingPosts} />
      <MomentOfTheDay data={zones.splash_moment} className={styles.momentOfTheDay} />
      <ShoppingPosts data={zones.splash_shopping} className={styles.shoppingPosts} />
      <FunModule
        className={styles.funModule}
        data={zones.splash_fun}
        isTrackable={isTrackable}
        trackingData={splashTrackingData}
      />
    </header>
  );
};

Splash.propTypes = {
  zones: PropTypes.object.isRequired,
};

export default Splash;
