import React from 'react';
import PropTypes from 'prop-types';
import FeedCard from '../../../FeedCard';
import styles from './shoppingPost.module.scss';
import ImagePlaceholder from '../../../../static/img/image-placeholder.svg';
export const ShoppingPost = ({ data, index }) => {
  const { category = {}, thumbnail = {}, url = '', title = '', id = '', trackingData = {} } = data;
  const baseTrackingData = {
    ...trackingData,
    unit_name: 'splash',
    subunit_type: 'package',
    subunit_name: 'shopping',
    item_type: 'card',
    item_name: id,
    position_in_unit: 2,
    position_in_subunit: index,
    target_content_type: 'buzz',
    target_content_id: id,
  };
  const categoryTrackingData = {
    ...baseTrackingData,
    item_type: 'text',
    item_name: category.label.toLowerCase(),
    target_content_type: 'feed',
    target_content_id: 6,
  };

  return (
    <FeedCard className={styles.shoppingPost} trackingData={baseTrackingData} isTrackable={true}>
      <FeedCard.Link url={url} isTrackable={true} trackingData={baseTrackingData}>
        <figure>
          <FeedCard.Thumbnail
            thumbnail={thumbnail?.standard?.url || ImagePlaceholder}
            alt={String(thumbnail?.standard?.alt ?? '')}
            ratioTablet = "1/1"
          />
        </figure>
      </FeedCard.Link>
      <div>
        <FeedCard.Link
          url={category.url}
          isTrackable={true}
          trackingData={categoryTrackingData}
        >
          <span className={styles.itemLabel}>{category.label}</span>
        </FeedCard.Link>
        <FeedCard.Link url={url} isTrackable={true} trackingData={baseTrackingData}>
          <h3>{title}</h3>
        </FeedCard.Link>
      </div>
    </FeedCard>
  );
};

ShoppingPost.propTypes = {
  data: PropTypes.shape({
    category: PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
    thumbnail: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })
};

export default ShoppingPost;
