import styles from '../promoBar.module.scss';

const AutomaticPromoBar = (props) => {
  const { className = '' } = props;
  return (
    <aside className={`${className} ${styles.container}`}>
      <div className={styles.content}>
        <h2 className={styles.title}>BuzzFeed Daily</h2>
        <p className={styles.body}>Keep up with the latest buzz in the BuzzFeed Daily newsletter!</p>
        <form className={styles.signupForm} aria-label="Sign up for BuzzFeed Daily newsletter">
          <label htmlFor="email" className={styles.visuallyHidden}>Enter your email:</label>
          <input
            type="email"
            id="email"
            value=""
            className={styles.emailInput} name="email" size="20"
            placeholder="Enter your email here"
          />
          <button type="submit" className={`${styles.button} ${styles.signup}`}>Sign up</button>
        </form>
      </div>
    </aside>
  );
}

export default AutomaticPromoBar;
